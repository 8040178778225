export const SocketUnplugged = ({ className }: { className?: string }) => (
    <span
        className={`flex-center h-[67px] w-[67px] rounded-full bg-ash p-2 pr-3 dark:bg-dark-2 md:h-[85px] md:w-[85px] ${
            className || ''
        }`}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="57"
            fill="none"
            viewBox="0 0 33 57"
        >
            <path
                fill="#2EDFF1"
                d="M12.82 16.632a.638.638 0 01-.638-.638V12.45a.637.637 0 011.275 0v3.562a.646.646 0 01-.638.62zM20.358 16.632a.638.638 0 01-.637-.638V12.45a.638.638 0 011.275 0v3.562a.646.646 0 01-.638.62zM18.421 22.14H14.75a1.445 1.445 0 01-1.292-2.125l.927-1.785a2.481 2.481 0 014.403 0l.926 1.785a1.445 1.445 0 01-1.292 2.125zm-1.836-3.97a1.198 1.198 0 00-1.07.647l-.927 1.785a.187.187 0 000 .178.161.161 0 00.153.085h3.672a.18.18 0 00.161-.085.188.188 0 000-.178l-.926-1.785a1.198 1.198 0 00-1.063-.646z"
            />
            <path
                className={'fill-[#333333] dark:fill-ash'}
                d="M9.63 25.2a.646.646 0 00.51.247h12.895a.611.611 0 00.502-.247l1.156-1.496a11.9 11.9 0 000-14.45l-1.156-1.496a.638.638 0 00-.502-.246H10.141a.671.671 0 00-.51.246L8.483 9.254a11.84 11.84 0 000 14.45L9.631 25.2zm-.144-15.198l.97-1.25H22.72l.96 1.25a10.574 10.574 0 010 12.929l-.96 1.25H10.455l-.969-1.242a10.574 10.574 0 010-12.928v-.009z"
            />
            <path
                className={'fill-[#333333] dark:fill-ash'}
                d="M30.286.762H2.712a1.998 1.998 0 00-1.99 1.997v40.095a1.99 1.99 0 001.99 1.989h2.55a.637.637 0 100-1.275h-2.55a.723.723 0 01-.714-.714V2.759a.723.723 0 01.714-.722h27.574a.723.723 0 01.714.722v40.095a.723.723 0 01-.714.714h-4.53c.053-.32.082-.645.084-.97v-6.927-1.062a1.819 1.819 0 00-1.81-1.81h-3.018V29.67a.637.637 0 00-1.275 0v3.128h-6.264V29.67a.637.637 0 00-1.275 0v3.128H9.155A1.81 1.81 0 007.37 34.38a.731.731 0 000 .196v8.024a6.316 6.316 0 006.307 6.307h2.55v6.893a.637.637 0 101.275 0v-6.893h2.04a6.307 6.307 0 005.882-4.063h4.87a1.988 1.988 0 001.99-1.99V2.76A1.997 1.997 0 0030.285.762zM8.619 34.609a.535.535 0 01.536-.536h3.043v.561a.637.637 0 101.275 0v-.56h6.264v.56a.637.637 0 101.275 0v-.56h3.035a.544.544 0 01.527.475v1.012a.528.528 0 01-.519.442h-14.9a.536.536 0 01-.536-.527v-.867zM19.533 47.63h-5.899a5.04 5.04 0 01-5.032-5.032v-5.39a1.7 1.7 0 00.553.094h14.892c.176-.002.35-.03.518-.085v5.38a5.04 5.04 0 01-5.032 5.033z"
            />
        </svg>
    </span>
);
