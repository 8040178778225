import { cva, VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { cn } from '@/service/utils/cn';

type TVariantButtons = VariantProps<typeof variantButtons>;

const variantButtons = cva(
    [
        'flex max-w-[250px] w-full items-center justify-center rounded-[47px] mx-auto px-10 py-3 disabled:cursor-not-allowed',
    ],
    {
        variants: {
            size: {
                sm: 'tx-large-medium',
                md: 'tx-large-medium',
                lg: 'tx-large-medium',
            },
            variant: {
                outlined:
                    'border h-10 text-brand hover:text-blue-7 disabled:border-gray-3 disabled:text-gray-2 disabled:dark:border-dark-1 disabled:dark:text-white',
                filledBlue:
                    'bg-gradient-to-r h-[50px] from-blue-2 rounded-[50px] to-blue-3 text-white shadow-lightButton hover:opacity-90 disabled:bg-gradient-to-r disabled:from-gray-8 disabled:to-gray-7 disabled:shadow-none disabled:hover:opacity-100 disabled:dark:from-gray-10 disabled:dark:to-gray-9',
                filledGray:
                    'bg-gradient-to-r h-[50px] from-gray-8 rounded-[50px] to-gray-7 dark:from-gray-10 dark:to-gray-9 text-white hover:opacity-90 disabled:bg-gradient-to-r disabled:from-gray-8 disabled:to-gray-7 disabled:shadow-none disabled:hover:opacity-100 disabled:dark:from-gray-10 disabled:dark:to-gray-9',
            },
            borderColor: {
                blue: 'border-blue-2 hover:border-blue-7',
                custom: '',
            },
        },
        defaultVariants: {
            size: 'lg',
            variant: 'filledBlue',
            borderColor: 'blue',
        },
    }
);

type TFormButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    TVariantButtons & {
        label?: ReactNode | string;
        className?: string;
        isSubmit?: boolean;
    };

export const FormButton = ({
    className,
    size,
    variant,
    borderColor,
    label,
    isSubmit,
    ...buttonProps
}: TFormButtonProps) => (
    <button
        className={cn(variantButtons({ size, variant, borderColor }), className)}
        type={isSubmit ? 'submit' : 'button'}
        {...buttonProps}
    >
        {label}
    </button>
);
