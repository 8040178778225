'use client';

import { FormButton } from '@/ui/common/Buttons/FormButton';

type TErrorButtonProps = {
    errorHandler?: () => void;
};

export const ErrorButton = ({ errorHandler }: TErrorButtonProps) => {
    return (
        <div className="mt-5 flex w-full flex-col-reverse items-center justify-center gap-[17px] md:mt-[55px] md:flex-row md:gap-5 lg:gap-[23px]">
            {!!errorHandler && (
                <FormButton
                    type="button"
                    variant="outlined"
                    className="mx-0 h-[50px] max-w-[280px] px-0 text-blue-2 md:h-[55px] md:max-w-[270px] lg:h-[50px]"
                    label="Try again?"
                    onClick={errorHandler}
                />
            )}
            <FormButton
                type="button"
                className="mx-0 h-[50px] max-w-[280px] px-0 md:h-[55px] md:max-w-[270px] lg:h-[50px]"
                label="Go back"
                onClick={() => {
                    window.location.href = window.location.origin;
                }}
            />
        </div>
    );
};
