import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '@/service/utils/cn';

type TVariantIcons = VariantProps<typeof variantIcons>;

const variantIcons = cva(
    [
        'group rounded-full bg-ash hover:bg-white hover:shadow-lightCard dark:bg-dark-2 dark:hover:bg-dark-3 dark:hover:shadow-dark flex justify-center items-center',
    ],
    {
        variants: {
            size: {
                small: 'w-4 h-4 md:w-5 md:h-5',
                large: 'h-10 w-10',
            },
        },
        defaultVariants: {
            size: 'large',
        },
    }
);

type TCloseIconProps = TVariantIcons & {
    className?: string;
};

export const CloseIcon = ({ className, size }: TCloseIconProps) => (
    <div className={cn(variantIcons({ size }), className)}>
        <div className="h-full w-full bg-close bg-contain bg-no-repeat group-[.darken]:bg-close-dark dark:bg-close-dark" />
    </div>
);
