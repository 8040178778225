import Link, { type LinkProps } from 'next/link';
import { ReactEventHandler } from 'react';

type TLinkIconProps = LinkProps & {
    href: string;
    iconSrc: string;
    alt?: string;
    className?: string;
    child?: React.ReactNode;
    target?: string;
    onError?: ReactEventHandler<HTMLImageElement>;
};

export const LinkIcon = ({
    href,
    iconSrc,
    className,
    alt,
    child,
    target,
    onError,
    ...rest
}: TLinkIconProps) => {
    return (
        <Link
            href={href}
            prefetch={false}
            className={className || ''}
            {...(target ? { target } : {})}
            passHref
            {...rest}
        >
            <img src={iconSrc} className="h-full w-full" alt={alt || ''} onError={onError} />
            {child}
        </Link>
    );
};
