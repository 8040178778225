export const UfoIcon = ({ className }: { className?: string }) => (
    <span
        className={`flex-center h-[67px] w-[67px] rounded-full bg-ash p-2 pr-3 dark:bg-dark-2 md:h-[85px] md:w-[85px] ${
            className || ''
        }`}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={52}
            height={49}
            fill="none"
            viewBox="0 0 52 49"
        >
            <path
                className={'fill-[#333333] dark:fill-ash'}
                d="M2.88 19.083a1.613 1.613 0 000 3.227h45.34a1.613 1.613 0 100-3.227H2.88zM0 20.697a2.88 2.88 0 012.88-2.88h45.34a2.88 2.88 0 110 5.76H2.88A2.88 2.88 0 010 20.697z"
            />
            <path
                className={'fill-[#333333] dark:fill-ash'}
                d="M15.542 10.745a.633.633 0 01.353-.108h19.4c.127 0 .25.037.354.107l10.684 7.18a.633.633 0 01-.706 1.051l-10.524-7.071H16.088L5.573 18.975a.633.633 0 01-.707-1.051l10.676-7.18z"
            />
            <path
                className={'fill-[#333333] dark:fill-ash'}
                d="M25.596 1.966a9.02 9.02 0 00-9.017 9.287.633.633 0 11-1.266.038 10.287 10.287 0 1120.566 0 .633.633 0 11-1.267-.038 9.021 9.021 0 00-9.016-9.287zM15.153 16.654a1.106 1.106 0 00-1.107 1.106.633.633 0 01-1.267 0 2.373 2.373 0 114.747 0 .633.633 0 01-1.267 0 1.106 1.106 0 00-1.106-1.106zm10.447 0a1.106 1.106 0 00-1.107 1.106.633.633 0 01-1.266 0 2.375 2.375 0 013.281-2.193 2.373 2.373 0 011.465 2.193.633.633 0 01-1.267 0 1.107 1.107 0 00-1.106-1.106zm10.437 0a1.106 1.106 0 00-1.106 1.106.633.633 0 01-1.267 0 2.373 2.373 0 114.747 0 .633.633 0 01-1.267 0 1.107 1.107 0 00-1.107-1.106z"
            />
            <path
                fill="#2EDFF1"
                d="M17.415 25.412c.336.097.53.449.432.785l-5.414 18.682a.633.633 0 11-1.216-.352l5.414-18.683a.633.633 0 01.784-.432zm5.365-.02a.633.633 0 01.551.706l-1.444 11.68a.633.633 0 11-1.257-.155l1.444-11.68a.633.633 0 01.706-.551zm11.968.02a.634.634 0 01.785.432l4.35 15.017a.634.634 0 01-1.217.353l-4.35-15.017a.633.633 0 01.432-.785zm-6.614-.021a.633.633 0 01.705.554l2.601 21.647a.633.633 0 11-1.258.151l-2.601-21.647a.633.633 0 01.553-.705z"
            />
        </svg>
    </span>
);
