import { ButtonHTMLAttributes, ReactNode } from 'react';
import { cn } from '@/service/utils/cn';

type TIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: ReactNode;
    label?: ReactNode | string;
    className?: string;
};

export const IconButton = ({ className, icon, label, ...buttonProps }: TIconButtonProps) => (
    <button
        className={cn('flex items-center justify-center overflow-visible', className)}
        type="button"
        {...buttonProps}
    >
        {icon}
        {label}
    </button>
);
